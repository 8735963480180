import { CreatePopupRequestData, ENDPOINTS } from 'api';
import axios from 'axios';

export const fetchUpdatePopup = ({
  popupId,
  files,
  ...data
}: { popupId: string } & Partial<CreatePopupRequestData>) => {
  if (files && files.length) {
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('data', JSON.stringify(data));

    return axios.patch(`${ENDPOINTS.POPUP_FILE}/${popupId}`, formData);
  }
  return axios.patch(`${ENDPOINTS.POPUP}/${popupId}`, data);
};
