import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  IconButton,
  Input,
  useControllableState,
  useToast,
  VStack
} from '@chakra-ui/react';
import {
  ChangeEventHandler,
  Dispatch,
  DragEventHandler,
  SetStateAction
} from 'react';

type Props = {
  fileFormats?: string[];
  files: { file: File; url: string }[];
  fileLimit?: number;
} & (
  | {
      onSelectFile: Dispatch<SetStateAction<Props['files']>>;
      isReadOnly?: false;
    }
  | {
      isReadOnly: true;
      onSelectFile?: Dispatch<SetStateAction<Props['files']>>;
    }
);

const DEFAULT_FILE_FORMATS = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'text/csv'
];

export const SpreadsheetUpload = ({
  fileFormats = DEFAULT_FILE_FORMATS,
  files: controlledFiles,
  fileLimit = 2,
  isReadOnly = false,
  onSelectFile
}: Props) => {
  const toast = useToast({
    position: 'top',
    duration: 2000,
    status: 'error'
  });

  const [uploadFiles, setUploadFiles] = useControllableState({
    value: controlledFiles,
    onChange: onSelectFile
  });

  const accept = fileFormats.join(',');
  const formats = ['.xlsx', '.xls', '.csv'];

  const handleFileInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { files, accept } = e.target;

    if (files) {
      if (!Array.from(files).every((file) => accept.includes(file.type))) {
        toast({
          description: `Only ${formats} types valid`,
          title: 'Invalid file type'
        });
        return;
      }

      if (files.length + uploadFiles.length > fileLimit) {
        toast({
          description: `Only ${fileLimit} files can be upload`,
          title: 'Max files'
        });
        return;
      }

      setUploadFiles((prev) => [
        ...prev,
        ...Array.from(files).map((file) => ({
          file,
          url: URL.createObjectURL(file)
        }))
      ]);
    }
  };

  const removeFile = (index: number) => {
    setUploadFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const onDragEnter: DragEventHandler<HTMLDivElement> = (e) =>
    e.currentTarget.setAttribute('data-dragenter', 'true');
  const onDragExit: DragEventHandler<HTMLDivElement> = (e) =>
    e.currentTarget.setAttribute('data-dragenter', 'false');

  const shouldDisplayContainer = !isReadOnly && uploadFiles.length < fileLimit;

  return (
    <FormControl>
      <VStack minW="23.25rem" spacing={3}>
        {uploadFiles.map((previewFile, index) => (
          <Flex
            key={`${previewFile.file.name}${index}`}
            position="relative"
            gap={2}
            p={2}
            border="1px solid"
            borderColor="gray.300"
          >
            {previewFile.file.name}
            {!isReadOnly && (
              <IconButton
                icon={<CloseIcon />}
                backgroundColor="gray.200"
                aria-label="Close button"
                variant="ghostWhite"
                size="xs"
                onClick={() => removeFile(index)}
              />
            )}
          </Flex>
        ))}
        {shouldDisplayContainer && (
          <Box
            border="1px dashed"
            borderColor="gray.300"
            position="relative"
            w="full"
            sx={{
              '&[data-dragenter="true"]': {
                bgColor: 'blue.50'
              }
            }}
            onDragEnter={onDragEnter}
            onDragLeave={onDragExit}
            onDrop={onDragExit}
          >
            <Center px={15} py={1}>
              <VStack spacing={1}>
                <Box color="gray.600" fontWeight="medium">
                  <Button variant="link" fontWeight="medium">
                    Upload a file
                  </Button>{' '}
                  or drag and drop
                </Box>
                <Box>{formats} up to 10 MB</Box>
              </VStack>
            </Center>
            <Input
              key="file"
              type="file"
              position="absolute"
              top="0"
              left="0"
              bottom="0"
              opacity="0"
              variant="unstyled"
              accept={accept}
              multiple={fileLimit > 1}
              onChange={handleFileInput}
            />
          </Box>
        )}
      </VStack>
    </FormControl>
  );
};
