import { GridItem } from '@chakra-ui/react';
import { FormElement } from 'containers';
import { InputElement, ParameterType } from 'enum';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings } from 'utils';

import {
  FormContentProps,
  GeneralSettingsFormField
} from '../FormContent.types';

export const CountryCurrencies = (props: FormContentProps): JSX.Element => {
  const [t] = useTranslation();

  return (
    <Fragment>
      <GridItem>{t('attribute.description.default_currency')}</GridItem>
      <GridItem>
        <FormElement
          getIsDisabled={({ formValues }) =>
            !formValues[GeneralSettingsFormField.EnabledMultiCurrencies]
          }
          type={InputElement.Select}
          name={GeneralSettingsFormField.ActiveCurrency}
          translationKey="currency"
          getPlaceholder={() => 'Language'}
          getOptions={({ t, settings }: GetOptionsArg) =>
            generateOptionsFromSettings(
              ParameterType.Currency,
              t,
              settings
            ).filter(({ value }) =>
              props.data?.subscriptionCurrencies.includes(value)
            )
          }
        />
      </GridItem>
    </Fragment>
  );
};
