export const ENDPOINTS = {
  LOGIN: 'auth/login',
  LOGOUT: 'auth/logout',
  PERMISSIONS: 'permissions',

  //Parameters
  PERMISSIONS_OPTIONS: 'permissions/options',
  PARAMETERS_OPTIONS: 'parameters-settings/options',
  PARAMETERS_OPTIONS_LIST: 'parameters-settings/options/list',
  PARAMETERS_VALUES: 'parameters-settings/translations',
  PARAMETERS_VALUES_LIST: 'parameters-settings/translations/list',

  // Admin
  ADMINS_RESET_PASSWORD: 'admins/reset-password/all',
  ADMINS: 'admins',
  ADMINS_FETCH: 'admins/fetch',
  ADMINS_ME: 'admins/me',

  // Customers
  CUSTOMERS_LIST: 'users/list',
  CUSTOMERS_LIST_ALL: 'users/dropdown/list',
  CUSTOMER: 'users',
  NICKNAME_HISTORY: 'histories/nicknames',
  EMAIL_HISTORY: 'histories/emails',
  ADMIN_COMMENTS: 'comments/admins',
  LINKED_ACCOUNTS: 'linked-accounts',
  LOGIN_SESSION: 'login-sessions',
  CUSTOMER_COMMENTS: 'comments/users',
  CUSTOMER_NOTES: 'notes',
  MESSAGE_IMAGES: 'assets/message-images',
  GALLERY_IMAGES: 'assets/photos',
  CUSTOMER_STORIES: 'stories',
  STORY: 'stories',
  FAVOURITES: 'favourites',
  LIKES: 'likes',
  BLACK_LIST: 'blacklists',
  CONVERSATIONS: 'conversations',
  CUSTOMER_REPORTS: 'reports/users',
  CUSTOMER_CSV: 'users/export-csv',
  BAD_WORDS: 'bad-words',
  CONVERSATION_ACCESS: 'conversations/check-access',
  VERIFY_EMAIL_LINK: 'verify-email-link',
  PASSWORD_RECOVERY_LINK: 'password-recovery-link',

  //Content
  GALLERY_IMAGES_LIST: 'assets/photos/list',
  MESSAGE_IMAGES_LIST: 'assets/message-images/list',
  STORIES_LIST: 'stories/list',

  // Log Activity
  LOG_ACTIVITY_LIST: 'log-activities/list',
  LOG_ACTIVITY_CSV: 'log-activities/export-csv',

  // Moderation
  MODERATION_PHOTO: 'moderation/photos',
  MODERATION_COMMENT: 'moderation/comments',
  MODERATION_MOTTO: 'moderation/mottos',
  MODERATION_ABOUT_ME: 'moderation/about-me',
  MODERATION_TOTAL: 'moderation/totals',

  // Contact Us
  CONTACT_US_TICKET: 'support',
  CONTACT_US_TOTAL: 'support/total',
  CONTACT_US_TICKET_LIST: 'support/list',
  CONTACT_US_TICKET_REPLY: 'support/reply',
  CONTACT_US_QUICK_REPLY: 'support/quick-reply',
  CONTACT_US_QUICK_REPLY_LIST: 'support/quick-reply/all',

  // Payment Management
  PAYMENT_METHOD: 'payments/methods',
  PAYMENT_SUBSCRIPTION: 'payments/subscriptions',
  PAYMENT_TRANSACTION: 'payments/transactions',

  // Popups Management
  POPUP: 'popups',
  POPUP_FILE: 'popups/file/upload',
  POPUP_LIST: 'popups/list',

  // Domains Config
  DOMAINS_CONFIG: 'domain-configs',
  DOMAINS_CONFIG_LIST: 'domain-configs/list',

  // Admin panel
  ADMIN_REGISTERED_USERS: 'statistics/operational/users/registered/list',
  ADMIN_USERS_LOGINS: 'statistics/operational/users/logins/list',
  ACTIVE_USERS: 'statistics/operational/users/online/list',
  PURCHASE_SUBSCRIPTIONS: 'statistics/financial/subscriptions/list',
  SENT_MESSAGES: 'statistics/operational/users/sent-messages/list',
  INCOME_BALANCE: 'statistics/financial/incomes/list',
  USERS_WITH_EACH_PROGRAM_TYPE:
    'statistics/financial/users/by-program-type/list',
  REPORTS_STATISTIC_BY_STATUS: 'statistics/operational/reports/by-status/list',
  REPORTS_STATISTIC_BY_AUTHOR:
    'statistics/operational/reports/by-author-type/list',
  CUSTOMER_REPORTS_STATISTIC:
    'statistics/operational/reports/users/by-reason/list',

  // Reports
  REPORT: 'reports',
  REPORT_TOTAL: 'reports/total',
  REPORT_CUSTOMER_LIST: 'reports/users/list',
  REPORT_ADMIN_LIST: 'reports/admins/list',

  // Blog Management
  ARTICLE: 'articles',
  ARTICLE_LIST: 'articles/list',

  // General Settings
  GENERAL_SETTINGS: 'general-settings',

  //CRM
  CRM_DOWNLOAD_CSV: 'crm/export-csv',
  CRM_SEND_EMAIL: 'crm/send-email',
  CRM_SEND_SMS: 'crm/send-sms',
  CRM_DELETE_USERS: 'crm/delete',
  CRM_BLOCK_USERS: 'crm/block',

  //Static Pages
  STATIC_PAGES: 'static-pages'
} as const;
