import { Box, FormLabel, VStack } from '@chakra-ui/react';
import { Avatar, Dropdown, ImageUpload } from 'components';
import { InfoCardContainer, ModalContainer } from 'containers';
import { ModalType } from 'enum';
import { OptionKey, ParameterType, ReportStatus } from 'enum/api';
import {
  useAppSelector,
  useModalActions,
  useNavigateToCustomer,
  useParameterTypeTranslation,
  useUpdateReport
} from 'hooks';
import startCase from 'lodash/startCase';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { selectSettings } from 'store';
import { ParamType } from 'types';
import { generateOptionsFromSettings } from 'utils';

import { getReportCustomerAccountInformation } from './Reports.utils';

export const CustomerReportDetails = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const { getTranslation } = useParameterTypeTranslation();

  const settings = useAppSelector(selectSettings);

  const { isOpen, onClose, meta } = useModalActions(
    ModalType.CustomerReportDetails
  );

  const { mutate, isLoading } = useUpdateReport();

  const navigateToCustomer = useNavigateToCustomer();

  const [selectedStatus, setSelectedStatus] = useState<ReportStatus[]>([]);

  useEffect(() => {
    setSelectedStatus(meta ? [meta.details.status] : []);
  }, [meta]);

  const actions: ParamType<typeof ModalContainer, 'footerButtons'> = [
    {
      text: t('actions.cancel'),
      variant: 'ghostGray'
    },
    {
      text: startCase(t('actions.save_changes')),
      isLoading,
      isPreventClose: true,
      onClick: () => {
        if (meta) {
          mutate(
            {
              reportId: meta.details.id,
              status: selectedStatus[0]
            },
            {
              onSuccess: onClose
            }
          );
        }
      }
    }
  ];

  const options = generateOptionsFromSettings(
    OptionKey.ReportStatus,
    t,
    settings
  );

  const { reported, reporter } = meta?.details || {};

  return (
    <ModalContainer
      header={t('attribute.title.report_details')}
      footerButtons={actions}
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
    >
      {!!meta && (
        <VStack spacing={6} alignItems="stretch">
          {!!reporter && (
            <Box>
              <FormLabel>{t('keywords.reporter')}</FormLabel>
              <Avatar
                title={reporter.displayedNickname}
                description={reporter.email}
              />
            </Box>
          )}
          <Box>
            <FormLabel>{t('keywords.status')}</FormLabel>
            <Dropdown
              isControllable
              options={options}
              values={selectedStatus}
              onChange={(values) => setSelectedStatus(values as ReportStatus[])}
            />
          </Box>
          <InfoCardContainer
            data={getReportCustomerAccountInformation({
              reportDate: meta.details.createdAt,
              reportReason: getTranslation(
                ParameterType.ReportReason,
                meta.details.reason
              ),
              t
            })}
          />
          {!!reported && (
            <Box>
              <FormLabel>{t('keywords.reported')}</FormLabel>
              <Box padding={4} bgColor="gray.100" borderRadius="1.25rem">
                <Avatar
                  title={reported.displayedNickname}
                  description={reported.email}
                  onClick={() => {
                    if (reported.hasAdminAccess) {
                      navigateToCustomer(reported.id);
                    } else {
                      const { profileUrl } = reported;

                      if (profileUrl) navigate(profileUrl);
                    }
                  }}
                />
              </Box>
            </Box>
          )}
          <Box>
            <FormLabel>{t('keywords.attachment')}</FormLabel>
            <ImageUpload
              files={[
                {
                  file: new File([meta.details.imageUrl], 'image'),
                  url: meta.details.imageUrl
                }
              ]}
              isReadOnly
            />
          </Box>
        </VStack>
      )}
    </ModalContainer>
  );
};
