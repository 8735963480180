import { FormField, InputElement } from 'enum';
import { getAllEnumValues } from 'enum-for';
import { OptionKey, ParameterType, ReportType } from 'enum/api';
import { GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings } from 'utils';
import * as yup from 'yup';

import { CreateReportFormData } from './CreateReport.types';

export const createReportFormSchema = ({
  withReportType
}: {
  withReportType: boolean;
}) =>
  ({
    fields: {
      [FormField.ReportType]: {
        getIsHidden: () => withReportType,
        type: InputElement.Select,
        translationKey: 'report_type',
        getPlaceholder: () => 'Select',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(OptionKey.ReportType, t, settings)
      },
      [FormField.Reason]: {
        type: InputElement.Select,
        translationKey: 'reason',
        getPlaceholder: () => 'Select',
        getOptions: ({ t, settings }: GetOptionsArg) =>
          generateOptionsFromSettings(ParameterType.ReportReason, t, settings)
      },
      [FormField.Comment]: {
        type: InputElement.TextArea,
        translationKey: 'comment',
        getPlaceholder: () => 'Comment',
        minHeight: 44,
        maxLength: 2000
      },
      [FormField.Files]: {
        type: InputElement.UploadImage,
        fileLimit: 1
      }
    },
    gridLayout: {
      templateAreas: `
      "${FormField.ReportType}"
      "${FormField.Reason}"
      "${FormField.Comment}"
      "${FormField.Files}"
    `,
      rowGap: 6
    }
  } as const);

export const createReportValidationSchema: yup.SchemaOf<CreateReportFormData> =
  yup.object({
    [FormField.ReportType]: yup
      .mixed()
      .oneOf(getAllEnumValues(ReportType))
      .optional(),
    [FormField.Reason]: yup.string().required(),
    [FormField.Comment]: yup.string().optional(),
    [FormField.Files]: yup
      .array(
        yup
          .object()
          .shape({
            file: yup.mixed().required(),
            url: yup.string().required()
          })
          .required()
      )
      .defined()
  });
