import {
  AmountOfProfileViewsBeforeUploadPhoto,
  AmountProfileViewsBeforeFillProfile,
  AmountProfileViewsBeforePay,
  AutomaticLabels,
  BlockingBadWordsCustomerMessages,
  ContactUsEmail,
  ContactUsTelegramLink,
  ContactUsWhatsappLink,
  CorporateEmail,
  CountryCurrencies,
  CustomerRestriction,
  DefaultLanguage,
  Domains,
  EnabledMultiCurrencies,
  FilesStorageParams,
  FreeMessages,
  InstagramFollowersMinCountToAutoverify,
  LengthUnit,
  LikesCount,
  MinMaxAgeCustomers,
  NotificationEmail,
  PasswordToViewCustomerMessages,
  PayThroughPaymentDomain,
  PercentageDisplayAnotherGender,
  RateForAutoBlock,
  RateForBlockingFlood,
  ReportEmail,
  RequestChatAccess,
  RequiredPremiumProfiles,
  SeeAllUsersWithFreePlan,
  SisterCountries,
  StartConversationsDailyLimit,
  UsersShouldRequestProfileDeletion,
  WarningBadWordsCustomerDescription,
  WarningBadWordsCustomerMessages,
  WelcomeMessageText
} from './FormItems';

export const FORM_SETTINGS_ITEMS = [
  SeeAllUsersWithFreePlan,
  UsersShouldRequestProfileDeletion,
  EnabledMultiCurrencies,
  PayThroughPaymentDomain,
  CountryCurrencies,
  PercentageDisplayAnotherGender,
  DefaultLanguage,
  Domains,
  SisterCountries,
  AmountOfProfileViewsBeforeUploadPhoto,
  RequiredPremiumProfiles,
  AmountProfileViewsBeforePay,
  AmountProfileViewsBeforeFillProfile,
  InstagramFollowersMinCountToAutoverify,
  AutomaticLabels,
  MinMaxAgeCustomers,
  WarningBadWordsCustomerMessages,
  WarningBadWordsCustomerDescription,
  BlockingBadWordsCustomerMessages,
  RateForAutoBlock,
  RateForBlockingFlood,
  FreeMessages,
  StartConversationsDailyLimit,
  LikesCount,
  LengthUnit,
  CustomerRestriction,
  RequestChatAccess,
  PasswordToViewCustomerMessages,
  CorporateEmail,
  ReportEmail,
  ContactUsEmail,
  ContactUsTelegramLink,
  ContactUsWhatsappLink,
  NotificationEmail,
  WelcomeMessageText,
  FilesStorageParams
];
