import { CreatePopupRequestData, ENDPOINTS } from 'api';
import axios from 'axios';

export const fetchCreatePopup = ({
  files,
  ...data
}: CreatePopupRequestData) => {
  if (files.length) {
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('data', JSON.stringify(data));

    return axios.post(ENDPOINTS.POPUP_FILE, formData);
  }

  return axios.post(ENDPOINTS.POPUP, data);
};
