import {
  AspectRatio,
  Box,
  HStack,
  Image,
  Tag,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon, EllipsisButton } from 'components';
import { ModalContainer, ScrollableContainer } from 'containers';
import { format } from 'date-fns';
import { ScrollDirection } from 'enum';
import { AssetType, ImageStatus } from 'enum/api';
import snakeCase from 'lodash/snakeCase';
import {
  MouseEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { getTagStatusColorByStatus } from '../../CustomerContent.utils';
import { UserData } from '../UserData';

import { ArrowIcons } from './ArrowIcons';
import { Asset } from './AssetCard.types';

type Props = {
  assets: Asset[];
  uploadedAt: Date;
  status: ImageStatus;
  statusAssignedAt: Date;
  assetActions: ReactNode;
  userId?: string;
};

export const AssetCard = ({
  assets,
  uploadedAt,
  status,
  statusAssignedAt,
  assetActions,
  userId
}: Props): JSX.Element => {
  const [t] = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const listRef = useRef<HTMLDivElement>(null);

  const [selectedAssetIndex, setSelectedAssetIndex] = useState(0);

  useEffect(() => {
    if (listRef.current) {
      const element = listRef.current.children.item(
        selectedAssetIndex
      ) as HTMLElement | null;

      if (element) {
        element.offsetParent?.scrollTo({
          behavior: 'smooth',
          left: element.offsetLeft
        });
      }
    }
  }, [selectedAssetIndex]);

  const tagLabel = `${t(`enum.image_status.${snakeCase(status)}`)} ${format(
    statusAssignedAt,
    '(dd.MM.yyyy)'
  )}`;
  const tagColorSchema = `status.${getTagStatusColorByStatus(status)}`;

  const previewAsset = assets.length && assets[selectedAssetIndex];

  const hasSeveralItems = assets.length > 1;

  const shouldRenderActions = status !== ImageStatus.NotAccepted;

  // prevent right click by video or image
  const onContextMenu: MouseEventHandler<
    HTMLVideoElement | HTMLImageElement
  > = (e) => {
    e.preventDefault();
  };

  const preview = (
    <Box
      position="relative"
      userSelect="none"
      width="100%"
      borderRadius="xl"
      overflow="hidden"
    >
      {previewAsset && (
        <>
          <ModalContainer isOpen={isOpen} onClose={onClose} size="2xl">
            <Image src={previewAsset.assetUrl} />
          </ModalContainer>
          <AspectRatio ratio={9 / 15} flex="1">
            {previewAsset.type === AssetType.Video ? (
              <video
                src={`${previewAsset.assetUrl}#t=0.1`}
                controls
                controlsList="nodownload"
                onContextMenu={onContextMenu}
              />
            ) : (
              <Image src={previewAsset.assetUrl} onClick={onOpen} />
            )}
          </AspectRatio>
        </>
      )}
      {shouldRenderActions && (
        <Box position="absolute" top={3} right={3}>
          <EllipsisButton>{assetActions}</EllipsisButton>
        </Box>
      )}
      {hasSeveralItems && (
        <ArrowIcons
          actions={[
            {
              id: 'left',
              Icon: ChevronLeftIcon,
              isDisabled: selectedAssetIndex === 0,
              onClick: () => setSelectedAssetIndex((prev) => prev - 1)
            },
            {
              id: 'right',
              Icon: ChevronRightIcon,
              isDisabled: selectedAssetIndex === assets.length - 1,
              onClick: () => setSelectedAssetIndex((prev) => prev + 1)
            }
          ]}
          position="absolute"
          top={3}
          left="50%"
          transform="translateX(-50%)"
        />
      )}
    </Box>
  );

  return (
    <Box
      padding={4}
      borderRadius="1.25rem"
      border="1px"
      borderColor="gray.300"
      display="inline-block"
      width={64}
    >
      <VStack spacing={3}>
        {preview}
        {hasSeveralItems && (
          <ScrollableContainer scrollDirection={ScrollDirection.Horizontal}>
            <HStack width="100%" spacing={2} ref={listRef}>
              {assets.map(({ id, assetUrl, type }, index) => (
                <AspectRatio
                  key={id}
                  ratio={5 / 8}
                  flex="0 0 22%"
                  borderRadius="xl"
                  border="1px"
                  overflow="hidden"
                  borderColor={
                    previewAsset && previewAsset.id === id
                      ? 'primary.500'
                      : 'transparent'
                  }
                  onClick={() => {
                    setSelectedAssetIndex(index);
                  }}
                >
                  {type === AssetType.Video ? (
                    <video
                      src={`${assetUrl}#t=0.1`}
                      onContextMenu={onContextMenu}
                    />
                  ) : (
                    <Image src={assetUrl} onContextMenu={onContextMenu} />
                  )}
                </AspectRatio>
              ))}
            </HStack>
          </ScrollableContainer>
        )}
        <VStack spacing={3} alignSelf="flex-start" alignItems="flex-start">
          {userId && <UserData userId={userId} />}
          <Tag size="md" colorScheme={tagColorSchema} variant="solid">
            {tagLabel}
          </Tag>
          <Text textStyle="srg" color="subtext">
            {format(uploadedAt, 'dd.MM.yyyy')}
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
};
