import { FormField, LengthUnits, Range, SystemLanguage } from 'enum';

export enum GeneralSettingsFormField {
  Country = 'country',
  SeeAllUsersWithFreePlan = 'seeAllUsersWithFreePlan',
  UsersShouldRequestProfileDeletion = 'usersShouldRequestProfileDeletion',
  PercentageForDisplayAnotherGender = 'percentageForDisplayAnotherGender',
  AmountOfProfileViewsBeforeUploadPhoto = 'amountOfProfileViewsBeforeUploadPhoto',
  AmountOfProfileViewsBeforePay = 'amountOfProfileViewsBeforePay',
  AmountOfProfileViewsFillProfile = 'amountOfProfileViewsFillProfile',
  CountOfFollowersToInstagramVerify = 'countOfFollowersToInstagramVerify',
  AutoLabelsAfterRegistration = 'autoLabelsAfterRegistration',
  MinMaxAgeCustomers = 'minMaxAgeCustomers',
  WarningBadWordsInCustomerMessage = 'warningBadWordsInCustomerMessage',
  WarningBadWordsInCustomerDescription = 'warningBadWordsInCustomerDescription',
  BlockingBadWordsInCustomerMessage = 'blockingBadWordsInCustomerMessage',
  WarningBadWordsInCustomerMessageByCustomer = 'warningBadWordsInCustomerMessageByCustomer',
  WarningBadWordsInCustomerDescriptionByCustomer = 'warningBadWordsInCustomerDescriptionByCustomer',
  BlockingBadWordsInCustomerMessageByCustomer = 'blockingBadWordsInCustomerMessageByCustomer',
  RateForAutoBlock = 'rateForAutoBlock',
  RateForBlockingFloods = 'rateForBlockingFloods',
  FreeMessages = 'freeMessages',
  StartedConversationsPerDayLimit = 'startedConversationsPerDayLimit',
  FreeLikesCount = 'freeLikesCount',
  RestrictCustomerFromAnotherCountry = 'restrictCustomerFromAnotherCountry',
  RequestChatAccessFromAnotherCountry = 'requestChatAccessFromAnotherCountry',
  PasswordForViewCustomerMessages = 'passwordForViewCustomerMessages',
  CorporateEmail = 'corporateEmail',
  ReportEmail = 'reportEmail',
  ContactUsEmail = 'contactUsEmail',
  ContactUsTelegramLink = 'contactUsTelegramLink',
  ContactUsWhatsappLink = 'contactUsWhatsappLink',
  NotificationEmail = 'notificationEmail',
  DefaultLanguage = 'defaultLanguage',
  Domains = 'domains',
  SisterCountries = 'sisterCountries',
  LengthUnit = 'lengthUnit',
  WelcomeMessageText = 'welcomeMessageText',
  RequiredPremiumProfiles = 'requiredPremiumProfiles',
  StorageFolderName = 'storageFolderName',
  StorageMaxEmailsImagesMale = 'storageMaxEmailsImagesMale',
  StorageMaxEmailsImagesFemale = 'storageMaxEmailsImagesFemale',
  StorageMaxNotificationsImagesMale = 'storageMaxNotificationsImagesMale',
  StorageMaxNotificationsImagesFemale = 'storageMaxNotificationsImagesFemale',
  EnabledMultiCurrencies = 'enabledMultiCurrencies',
  ActiveCurrency = 'activeCurrency',
  PayThroughPaymentDomain = 'payThroughPaymentDomain'
}

export type GeneralSettingsFormData = {
  [GeneralSettingsFormField.Country]: string;
  [GeneralSettingsFormField.SeeAllUsersWithFreePlan]: boolean;
  [GeneralSettingsFormField.PercentageForDisplayAnotherGender]:
    | number
    | undefined;
  [GeneralSettingsFormField.AmountOfProfileViewsBeforePay]: number | undefined;
  [GeneralSettingsFormField.AmountOfProfileViewsFillProfile]:
    | number
    | undefined;
  [GeneralSettingsFormField.CountOfFollowersToInstagramVerify]:
    | number
    | undefined;
  [GeneralSettingsFormField.AutoLabelsAfterRegistration]: {
    [FormField.Gender]: string | null;
    [FormField.Label]: string[];
  }[];
  [GeneralSettingsFormField.MinMaxAgeCustomers]: {
    [Range.Start]: number;
    [Range.End]: number;
  };
  [GeneralSettingsFormField.WarningBadWordsInCustomerMessage]: string[];
  [GeneralSettingsFormField.WarningBadWordsInCustomerMessageByCustomer]: {
    [FormField.User]: {
      [FormField.Id]: string | null;
      [FormField.Email]: string | null;
    };
    [FormField.BadWords]: string[];
  }[];
  [GeneralSettingsFormField.WarningBadWordsInCustomerDescription]: string[];
  [GeneralSettingsFormField.WarningBadWordsInCustomerDescriptionByCustomer]: {
    [FormField.User]: {
      [FormField.Id]: string | null;
      [FormField.Email]: string | null;
    };
    [FormField.BadWords]: string[];
  }[];
  [GeneralSettingsFormField.BlockingBadWordsInCustomerMessage]: string[];
  [GeneralSettingsFormField.BlockingBadWordsInCustomerMessageByCustomer]: {
    [FormField.User]: {
      [FormField.Id]: string | null;
      [FormField.Email]: string | null;
    };
    [FormField.BadWords]: string[];
  }[];
  [GeneralSettingsFormField.RateForAutoBlock]: {
    [FormField.MessageAmount]: number | undefined;
    [FormField.Period]: number | undefined;
    [FormField.BadWords]: string[];
  };
  [GeneralSettingsFormField.RateForBlockingFloods]: {
    [FormField.MessageAmount]: number | undefined;
    [FormField.Period]: number | undefined;
  };
  [GeneralSettingsFormField.FreeMessages]: {
    [FormField.CustomerType]: string | undefined;
    [FormField.MessageAmount]: number | undefined;
    [FormField.ConversationAmount]: number | undefined;
  }[];
  [GeneralSettingsFormField.AmountOfProfileViewsBeforeUploadPhoto]: {
    [FormField.CustomerType]: string | undefined;
    [FormField.CustomersAmount]: number | undefined;
  }[];
  [GeneralSettingsFormField.RequiredPremiumProfiles]: {
    [FormField.CustomerType]: string | undefined;
    [FormField.Gender]: string | undefined;
  }[];
  [GeneralSettingsFormField.FreeLikesCount]: {
    [FormField.FreeLikesAmount]: number | undefined;
    [FormField.PremiumLikesAmountPerDay]: number | undefined;
  };
  [GeneralSettingsFormField.RestrictCustomerFromAnotherCountry]: {
    [FormField.CustomerType]: string | null | undefined;
    [FormField.OtherCustomerType]: string | null | undefined;
    [FormField.Countries]: string[];
    [FormField.EverybodyCanSeeEverybody]: boolean;
    [FormField.AppliedForFreeProgramType]?: boolean;
  }[];
  [GeneralSettingsFormField.RequestChatAccessFromAnotherCountry]: {
    [FormField.CustomerTypes]: string[];
    [FormField.OtherCustomerTypes]: string[];
    [FormField.Genders]: string[];
    [FormField.OtherGenders]: string[];
    [FormField.ProgramTypes]: string[];
    [FormField.OtherProgramTypes]: string[];
    [FormField.Countries]: string[];
    [FormField.EverybodyCanMessageToEverybody]: boolean;
  }[];
  [GeneralSettingsFormField.PasswordForViewCustomerMessages]: {
    [FormField.Password]: string;
    [FormField.PasswordConfirmation]: string;
  };
  [GeneralSettingsFormField.CorporateEmail]: string;
  [GeneralSettingsFormField.ReportEmail]: string;
  [GeneralSettingsFormField.ContactUsEmail]: string;
  [GeneralSettingsFormField.ContactUsTelegramLink]: string;
  [GeneralSettingsFormField.ContactUsWhatsappLink]: string;
  [GeneralSettingsFormField.NotificationEmail]: string;
  [GeneralSettingsFormField.WelcomeMessageText]: string;
  [GeneralSettingsFormField.DefaultLanguage]: SystemLanguage | null;
  [GeneralSettingsFormField.Domains]: string[];
  [GeneralSettingsFormField.SisterCountries]: string[];
  [GeneralSettingsFormField.LengthUnit]: LengthUnits;
  [GeneralSettingsFormField.StorageFolderName]?: string | null;
  [GeneralSettingsFormField.StorageMaxEmailsImagesMale]?: number | null;
  [GeneralSettingsFormField.StorageMaxEmailsImagesFemale]?: number | null;
  [GeneralSettingsFormField.StorageMaxNotificationsImagesMale]?: number | null;
  [GeneralSettingsFormField.StorageMaxNotificationsImagesFemale]?:
    | number
    | null;
  [GeneralSettingsFormField.UsersShouldRequestProfileDeletion]: boolean;
  [GeneralSettingsFormField.EnabledMultiCurrencies]: boolean;
  [GeneralSettingsFormField.ActiveCurrency]: string;
  [GeneralSettingsFormField.PayThroughPaymentDomain]: boolean;
  [GeneralSettingsFormField.StartedConversationsPerDayLimit]:
    | number
    | undefined;
};

export type FormContentProps = {
  prefilledFields: GeneralSettingsFormField[];
  data?: { subscriptionCurrencies: string[] };
};
