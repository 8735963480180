import { Center, Spinner, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dropdown, RightSideButtons } from 'components';
import { ContentContainer, RightSide, ScrollableContainer } from 'containers';
import { DropdownType } from 'enum';
import { OptionKey } from 'enum/api';
import {
  useAppSelector,
  useChakraToast,
  useGeneralSettings,
  usePermissions,
  useUpdateGeneralSettings
} from 'hooks';
import { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { selectSettings } from 'store';
import { generateOptionsFromSettings } from 'utils';

import {
  FormContent,
  GeneralSettingsFormData,
  GeneralSettingsFormField
} from './FormContent';
import {
  getMockSettingsDefaultValue,
  serializeGeneralSettingResponse,
  serializeGeneralSettingsRequestData
} from './GeneralSettings.utils';
import { generalSettingsValidationSchema } from './validation';

export const GeneralSettings = (): JSX.Element => {
  const [t] = useTranslation();
  const toast = useChakraToast();

  const settings = useAppSelector(selectSettings);
  const { checkPermissions } = usePermissions();

  const form = useForm<GeneralSettingsFormData>({
    mode: 'onChange',
    resolver: yupResolver(generalSettingsValidationSchema),
    defaultValues: getMockSettingsDefaultValue(settings)
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty },
    control,
    watch
  } = form;

  const country = watch(GeneralSettingsFormField.Country);

  const { data, isLoading } = useGeneralSettings(country);

  const { mutate } = useUpdateGeneralSettings();

  useEffect(() => {
    if (data) {
      const { reset, getValues } = form;
      const country = getValues(GeneralSettingsFormField.Country);

      reset(serializeGeneralSettingResponse(data, country));
    }
  }, [form, data]);

  const countryOptions = useMemo(
    () => generateOptionsFromSettings(OptionKey.Country, t, settings),
    [t, settings]
  );

  const onSubmit = handleSubmit((data) => {
    if (country) {
      mutate(
        {
          country,
          body: serializeGeneralSettingsRequestData({ data, checkPermissions })
        },
        {
          onSuccess: () => {
            toast({
              title: t('messages.successfully_saved'),
              status: 'success'
            });
          }
        }
      );
    }
  });

  const getPageContent = () => {
    if (!country) {
      return (
        <Text>{t('attribute.description.select_country_to_see_settings')}</Text>
      );
    }

    if (isLoading) {
      return (
        <Center height="100%">
          <Spinner
            thickness="0.25rem"
            speed="1s"
            emptyColor="gray.200"
            color="primary.500"
            size="xl"
          />
        </Center>
      );
    }

    const prefilledFields = data?.viewConversationsPassword
      ? [GeneralSettingsFormField.PasswordForViewCustomerMessages]
      : [];

    if (!!data) {
      return (
        <FormProvider {...form}>
          <FormContent
            prefilledFields={prefilledFields}
            data={{ subscriptionCurrencies: data.countryCurrencies }}
          />
        </FormProvider>
      );
    }

    return null;
  };

  return (
    <ScrollableContainer>
      <ContentContainer>{getPageContent()}</ContentContainer>
      <RightSide>
        <VStack spacing={10} alignItems="stretch">
          <Controller
            control={control}
            name={GeneralSettingsFormField.Country}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                type={DropdownType.Select}
                isControllable
                isSearchable
                options={countryOptions}
                values={value ? [value] : []}
                onChange={(values) => onChange(values[0])}
                placeholder={t('keywords.country')}
              />
            )}
          />
          <RightSideButtons
            onSubmit={onSubmit}
            isDisabledSubmit={!isDirty}
            onCancel={() => {
              reset();
            }}
          />
        </VStack>
      </RightSide>
    </ScrollableContainer>
  );
};
