import { CreatePopupRequestData } from 'api';
import { FormField, InputElement, PopupAction, PopupButtonLink } from 'enum';
import { getAllEnumValues } from 'enum-for';
import {
  OptionKey,
  ParameterType,
  PopupDisplayPage,
  SchedulerFrequency
} from 'enum/api';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import { DefaultValues } from 'react-hook-form';
import { RequiredNotNullable } from 'types';
import { GetIsHiddenFnArg, GetOptionsArg } from 'types/form';
import { generateOptionsFromSettings, generateYesNoOptions, YES } from 'utils';
import * as yup from 'yup';

import { CreatePopupFormData } from './CreatePopup.types';

export const createPopupFormSchema = {
  fields: {
    [FormField.Title]: {
      type: InputElement.Input,
      translationKey: 'title',
      getPlaceholder: () => 'Title',
      maxLength: 50
    },
    [FormField.Message]: {
      type: InputElement.TextArea,
      translationKey: 'popup_message',
      getPlaceholder: () => 'Popup message',
      minHeight: 36,
      maxLength: 500
    },
    [FormField.ImageLink]: {
      type: InputElement.Input,
      translationKey: 'image_link',
      getPlaceholder: () => 'Image link'
    },
    [FormField.CustomerType]: {
      type: InputElement.MultiSelect,
      translationKey: 'customer_type',
      getPlaceholder: () => 'Customer type',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.CustomerType, t, settings)
    },
    [FormField.Gender]: {
      type: InputElement.MultiSelect,
      translationKey: 'gender',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.Gender, t, settings)
    },
    [FormField.Countries]: {
      type: InputElement.MultiSelect,
      isSearchable: true,
      translationKey: 'country',
      getPlaceholder: () => 'Country',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.Country, t, settings)
    },
    [FormField.Actions]: {
      type: InputElement.MultiSelect,
      translationKey: 'actions_for_popup',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.PopupAction, t, settings)
    },
    [FormField.ActionBtnOneTitle]: {
      type: InputElement.Input,
      translationKey: 'action_button_one_title',
      getPlaceholder: () => 'actions.login',
      maxLength: 100,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } = arg as GetIsHiddenFnArg<CreatePopupFormData>;

        return !formValues[FormField.Actions]?.includes(PopupAction.ButtonOne);
      }
    },
    [FormField.ActionBtnOneLink]: {
      type: InputElement.Select,
      translationKey: 'action_button_one_link',
      getPlaceholder: () => 'Button one link',
      maxLength: 100,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } = arg as GetIsHiddenFnArg<CreatePopupFormData>;

        return !formValues[FormField.Actions]?.includes(PopupAction.ButtonOne);
      },
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.PopupActionLink, t, settings)
    },
    [FormField.ActionBtnOneIsColored]: {
      type: InputElement.Toggle,
      translationKey: 'action_button_one_is_colored',
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } = arg as GetIsHiddenFnArg<CreatePopupFormData>;

        return !formValues[FormField.Actions]?.includes(PopupAction.ButtonOne);
      }
    },
    [FormField.ActionBtnTwoTitle]: {
      type: InputElement.Input,
      translationKey: 'action_button_two_title',
      getPlaceholder: () => 'actions.login',
      maxLength: 100,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } = arg as GetIsHiddenFnArg<CreatePopupFormData>;

        return !formValues[FormField.Actions]?.includes(PopupAction.ButtonTwo);
      }
    },
    [FormField.ActionBtnTwoLink]: {
      type: InputElement.Select,
      translationKey: 'action_button_two_link',
      getPlaceholder: () => 'Button two link',
      maxLength: 100,
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } = arg as GetIsHiddenFnArg<CreatePopupFormData>;

        return !formValues[FormField.Actions]?.includes(PopupAction.ButtonTwo);
      },
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.PopupActionLink, t, settings)
    },
    [FormField.ActionBtnTwoIsColored]: {
      type: InputElement.Toggle,
      translationKey: 'action_button_two_is_colored',
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } = arg as GetIsHiddenFnArg<CreatePopupFormData>;

        return !formValues[FormField.Actions]?.includes(PopupAction.ButtonTwo);
      }
    },
    [FormField.FrequencyToggle]: {
      type: InputElement.Select,
      translationKey: 'frequency_popup_appearance',
      getPlaceholder: () => 'Select',
      getOptions: ({ t }: GetOptionsArg) => generateYesNoOptions(t)
    },
    [FormField.FrequencyScheduler]: {
      type: InputElement.FrequencyScheduler,
      translationKey: 'schedule',
      getPlaceholder: () => 'Select weekdays',
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } = arg as GetIsHiddenFnArg<CreatePopupFormData>;

        return formValues[FormField.FrequencyToggle] !== YES;
      }
    },
    [FormField.PopupPages]: {
      type: InputElement.MultiSelect,
      translationKey: 'pages_for_settings_popup',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(OptionKey.PopupDisplayPage, t, settings)
    },
    [FormField.ProgramType]: {
      type: InputElement.MultiSelect,
      translationKey: 'program_type',
      getPlaceholder: () => 'Select',
      getOptions: ({ t, settings }: GetOptionsArg) =>
        generateOptionsFromSettings(ParameterType.ProgramType, t, settings)
    },
    [FormField.AutoClosePopup]: {
      type: InputElement.Toggle,
      translationKey: 'auto_close_popup'
    },
    [FormField.Seconds]: {
      type: InputElement.Input,
      translationKey: 'seconds_before_popup_closed',
      autoFocus: true,
      getPlaceholder: () => '0',
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } = arg as GetIsHiddenFnArg<CreatePopupFormData>;

        return !formValues[FormField.AutoClosePopup];
      }
    },
    [FormField.EnableMessagesRange]: {
      type: InputElement.Toggle,
      translationKey: 'send_x_messages'
    },
    [FormField.MessagesRangeMin]: {
      type: InputElement.Input,
      translationKey: 'messages_amount_min',
      autoFocus: true,
      minValue: 0,
      getPlaceholder: () => '0',
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } = arg as GetIsHiddenFnArg<CreatePopupFormData>;

        return !formValues[FormField.EnableMessagesRange];
      }
    },
    [FormField.MessagesRangeMax]: {
      type: InputElement.Input,
      translationKey: 'messages_amount_max',
      minValue: 0,
      getPlaceholder: () => '0',
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } = arg as GetIsHiddenFnArg<CreatePopupFormData>;

        return !formValues[FormField.EnableMessagesRange];
      }
    },
    [FormField.EnableSeenStoriesRange]: {
      type: InputElement.Toggle,
      translationKey: 'seen_x_stories'
    },
    [FormField.SeenStoriesRangeMin]: {
      type: InputElement.Input,
      translationKey: 'seen_stories_amount_min',
      autoFocus: true,
      minValue: 0,
      getPlaceholder: () => '0',
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } = arg as GetIsHiddenFnArg<CreatePopupFormData>;

        return !formValues[FormField.EnableSeenStoriesRange];
      }
    },
    [FormField.SeenStoriesRangeMax]: {
      type: InputElement.Input,
      translationKey: 'seen_stories_amount_max',
      minValue: 0,
      getPlaceholder: () => '0',
      getIsHidden: (arg: GetIsHiddenFnArg) => {
        const { formValues } = arg as GetIsHiddenFnArg<CreatePopupFormData>;

        return !formValues[FormField.EnableSeenStoriesRange];
      }
    },
    [FormField.Files]: {
      type: InputElement.UploadSpreadsheet,
      fileLimit: 1
    }
  },
  gridLayout: {
    templateAreas: `
      "heading heading heading"
      "${FormField.Title} . ."
      "${FormField.Message} ${FormField.Message} ."
      "${FormField.ImageLink} ${FormField.ImageLink} ."
      ". . ."
      "settings settings settings"
      "${FormField.Actions} ${FormField.ActionBtnOneTitle} ."
      "${FormField.CustomerType} ${FormField.ActionBtnOneLink} ."
      "${FormField.Gender} ${FormField.ActionBtnOneIsColored} ."
      "${FormField.Countries} ${FormField.ActionBtnTwoTitle} ."
      "${FormField.PopupPages} ${FormField.ActionBtnTwoLink} ."
      "${FormField.ProgramType} ${FormField.ActionBtnTwoIsColored} ."
      "${FormField.FrequencyToggle} ${FormField.Files} ."
      "${FormField.FrequencyScheduler} . ."
      "${FormField.AutoClosePopup} ${FormField.EnableMessagesRange} ${FormField.EnableSeenStoriesRange}"
      "${FormField.Seconds} ${FormField.MessagesRangeMin} ${FormField.SeenStoriesRangeMin}"
      ". ${FormField.MessagesRangeMax} ${FormField.SeenStoriesRangeMax}"
    `,
    gridTemplateColumns: '1fr 1fr 1fr',
    rowGap: 6,
    columnGap: 10
  }
} as const;

export const createPopupValidationSchema: yup.SchemaOf<CreatePopupFormData> =
  yup.object({
    [FormField.Title]: yup.string().optional().max(50),
    [FormField.Message]: yup.string().optional().max(500),
    [FormField.ImageLink]: yup.string().required().url(),
    [FormField.CustomerType]: yup.array().when(FormField.Files, {
      is: (value?: string) => value?.length,
      then: yup.array(yup.string()).max(0),
      otherwise: yup.array(yup.string().required()).min(1)
    }),
    [FormField.Gender]: yup.array().when(FormField.Files, {
      is: (value?: string) => value?.length,
      then: yup.array(yup.string()).max(0),
      otherwise: yup.array(yup.string().required()).min(1)
    }),
    [FormField.Countries]: yup.array().when(FormField.Files, {
      is: (value?: string) => value?.length,
      then: yup.array(yup.string()).max(0),
      otherwise: yup.array(yup.string().required()).min(1)
    }),
    [FormField.Actions]: yup
      .array(yup.mixed().oneOf(getAllEnumValues(PopupAction)))
      .min(1),
    [FormField.ActionBtnOneTitle]: yup.string().when(FormField.Actions, {
      is: (value?: string) => value?.includes(PopupAction.ButtonOne),
      then: yup.string().required(),
      otherwise: yup.string().optional()
    }),
    [FormField.ActionBtnOneLink]: yup.string().when(FormField.Actions, {
      is: (value?: string) => value?.includes(PopupAction.ButtonOne),
      then: yup.string().oneOf(getAllEnumValues(PopupButtonLink)).required(),
      otherwise: yup.string().optional()
    }),
    [FormField.ActionBtnOneIsColored]: yup.boolean().notRequired(),
    [FormField.ActionBtnTwoTitle]: yup.string().when(FormField.Actions, {
      is: (value?: string) => value?.includes(PopupAction.ButtonTwo),
      then: yup.string().required(),
      otherwise: yup.string().optional()
    }),
    [FormField.ActionBtnTwoLink]: yup.string().when(FormField.Actions, {
      is: (value?: string) => value?.includes(PopupAction.ButtonTwo),
      then: yup.string().oneOf(getAllEnumValues(PopupButtonLink)).required(),
      otherwise: yup.string().optional()
    }),
    [FormField.ActionBtnTwoIsColored]: yup.boolean().notRequired(),
    [FormField.FrequencyToggle]: yup.string().required(),
    [FormField.PopupPages]: yup
      .array(yup.mixed().oneOf(getAllEnumValues(PopupDisplayPage)))
      .min(1),
    [FormField.ProgramType]: yup.array().when(FormField.Files, {
      is: (value?: string) => value?.length,
      then: yup.array(yup.string()).max(0),
      otherwise: yup.array(yup.string().required()).min(1)
    }),
    [FormField.AutoClosePopup]: yup.boolean().defined(),
    [FormField.Seconds]: yup.number().when(FormField.AutoClosePopup, {
      is: true,
      then: yup.number().required().min(0, 'positive_value'),
      otherwise: yup.number().notRequired()
    }),
    [FormField.EnableMessagesRange]: yup.boolean().defined(),
    [FormField.MessagesRangeMin]: yup
      .number()
      .when(FormField.EnableMessagesRange, {
        is: true,
        then: yup.number().required().min(0, 'positive_value'),
        otherwise: yup.number().notRequired()
      }),
    [FormField.MessagesRangeMax]: yup
      .number()
      .when(FormField.EnableMessagesRange, {
        is: true,
        then: yup.number().notRequired().nullable(),
        otherwise: yup.number().notRequired()
      }),
    [FormField.EnableSeenStoriesRange]: yup.boolean().defined(),
    [FormField.SeenStoriesRangeMin]: yup
      .number()
      .when(FormField.EnableSeenStoriesRange, {
        is: true,
        then: yup.number().required().min(0, 'positive_value'),
        otherwise: yup.number().notRequired()
      }),
    [FormField.SeenStoriesRangeMax]: yup
      .number()
      .when(FormField.EnableSeenStoriesRange, {
        is: true,
        then: yup.number().notRequired().nullable(),
        otherwise: yup.number().notRequired()
      }),
    [FormField.FrequencyScheduler]: yup
      .object()
      .when(FormField.FrequencyToggle, {
        is: (value?: string) => value === YES,
        then: yup
          .object()
          .shape({
            weekDays: yup
              .array(yup.number().integer().required())
              .min(1)
              .required(),
            frequency: yup
              .mixed()
              .required()
              .oneOf(getAllEnumValues(SchedulerFrequency))
          })
          .required(),
        otherwise: yup
          .object()
          .shape({
            weekDays: yup
              .array(yup.number().integer().notRequired())
              .min(1)
              .notRequired(),
            frequency: yup
              .mixed()
              .notRequired()
              .oneOf(getAllEnumValues(SchedulerFrequency))
          })
          .notRequired()
      }),
    [FormField.Files]: yup
      .array(
        yup
          .object()
          .shape({
            file: yup.mixed().required(),
            url: yup.string().required()
          })
          .required()
      )
      .defined()
  });

export const getDefaultFormValues = (): DefaultValues<CreatePopupFormData> => ({
  [FormField.Title]: '',
  [FormField.Message]: '',
  [FormField.ImageLink]: '',
  [FormField.CustomerType]: [],
  [FormField.Gender]: [],
  [FormField.Countries]: [],
  [FormField.Actions]: [],
  [FormField.ActionBtnOneTitle]: '',
  [FormField.ActionBtnOneLink]: '',
  [FormField.ActionBtnOneIsColored]: false,
  [FormField.ActionBtnTwoTitle]: '',
  [FormField.ActionBtnTwoLink]: '',
  [FormField.ActionBtnTwoIsColored]: false,
  [FormField.FrequencyToggle]: undefined,
  [FormField.FrequencyScheduler]: undefined,
  [FormField.PopupPages]: [],
  [FormField.ProgramType]: [],
  [FormField.AutoClosePopup]: false,
  [FormField.Seconds]: undefined,
  [FormField.EnableMessagesRange]: false,
  [FormField.MessagesRangeMin]: undefined,
  [FormField.MessagesRangeMax]: null,
  [FormField.EnableSeenStoriesRange]: false,
  [FormField.SeenStoriesRangeMin]: undefined,
  [FormField.SeenStoriesRangeMax]: null,
  [FormField.Files]: []
});

export const serializeRequestData = (
  formData: CreatePopupFormData
): CreatePopupRequestData => {
  const data = formData as RequiredNotNullable<
    CreatePopupFormData,
    FormField.Seconds
  >;

  const serializedData = pickBy(
    {
      imageLink: data[FormField.ImageLink],
      pages: data[FormField.PopupPages],
      actions: data[FormField.Actions],
      enabledFrequentAppearance: data[FormField.FrequencyToggle] === YES,
      programType: data[FormField.ProgramType],
      countries: data[FormField.Countries],
      customerType: data[FormField.CustomerType],
      gender: data[FormField.Gender],
      enabledAutoClose: data[FormField.AutoClosePopup],
      autoCloseTimeout: data[FormField.Seconds],
      messagesRange: [
        data[FormField.MessagesRangeMin],
        data[FormField.MessagesRangeMax]
      ],
      seenStoriesRange: [
        data[FormField.SeenStoriesRangeMin],
        data[FormField.SeenStoriesRangeMax]
      ],
      files: data[FormField.Files].map(({ file }) => file),
      ...(data[FormField.Actions].includes(PopupAction.ButtonOne) && {
        actionButtonOne: {
          title: data[FormField.ActionBtnOneTitle],
          link: data[FormField.ActionBtnOneLink],
          isColored: data[FormField.ActionBtnOneIsColored]
        }
      }),
      ...(data[FormField.Actions].includes(PopupAction.ButtonTwo) && {
        actionButtonTwo: {
          title: data[FormField.ActionBtnTwoTitle],
          link: data[FormField.ActionBtnTwoLink],
          isColored: data[FormField.ActionBtnTwoIsColored]
        }
      }),
      ...(data[FormField.FrequencyToggle] === YES &&
        data[FormField.FrequencyScheduler] && {
          frequencyScheduler: {
            weekDays: data[FormField.FrequencyScheduler].weekDays,
            frequency: data[FormField.FrequencyScheduler].frequency
          }
        })
    },
    identity
  ) as CreatePopupRequestData;

  return {
    ...serializedData,
    title: data[FormField.Title],
    message: data[FormField.Message],
    enabledFrequentAppearance: data[FormField.FrequencyToggle] === YES,
    enabledMessagesRange: data[FormField.EnableMessagesRange],
    enabledSeenStoriesRange: data[FormField.EnableSeenStoriesRange]
  };
};
