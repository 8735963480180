import { ImageUpload, Password, SpreadsheetUpload } from 'components';
import { InputElement } from 'enum';
import { PermissionAction } from 'enum/api';
import { useHideFormElement, usePermissions } from 'hooks';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormElementProps, WithFormMetaData } from './FormConstructor.types';
import {
  FormCheckbox,
  FormCheckboxGroup,
  FormDatePicker,
  FormDateRangePicker,
  FormDateTimeRangePicker,
  FormFrequencyScheduler,
  FormInput,
  FormMdEditor,
  FormNumberInput,
  FormRange,
  FormSelect,
  FormTextArea,
  FormToggle,
  Location
} from './components';

type Props = FormElementProps & WithFormMetaData;

export const FormElement = (props: Props): JSX.Element | null => {
  const [t] = useTranslation();

  const form = useFormContext();

  const { register, formState, getFieldState, control } = form;

  const { checkPermissions } = usePermissions();

  const { isHidden, isDisabled } = useHideFormElement({
    getIsHidden: props.getIsHidden,
    getIsDisabled: props.getIsDisabled
  });

  if (isHidden) return null;

  const { getIsDisabled, getIsHidden, ...rest } = props;

  const elementProps = {
    ...rest,
    isDisabled
  };

  switch (elementProps.type) {
    case InputElement.Input:
      return <FormInput {...elementProps} />;

    case InputElement.NumberInput:
      return <FormNumberInput {...elementProps} />;

    case InputElement.Password: {
      const {
        type,
        translationKey,
        getPlaceholder,
        name,
        hintTranslationKey,
        resource,
        isDisabled,
        shouldPreventVisibility,
        ...rest
      } = elementProps;
      const { error } = getFieldState(name, formState);
      const hasUpdateAccess = resource
        ? checkPermissions({
            resource,
            actions: PermissionAction.Update
          })
        : true;

      const canSeePassword = resource
        ? checkPermissions({
            resource,
            actions: PermissionAction.Read
          })
        : true;

      return (
        <Password
          {...rest}
          {...register(name)}
          isDisabled={isDisabled || !hasUpdateAccess}
          shouldPreventVisibility={shouldPreventVisibility || !canSeePassword}
          label={t(`keywords.${translationKey}`)}
          isInvalid={!!error}
          placeholder={getPlaceholder(t)}
          errorKey={error?.message}
          hint={
            !error && hintTranslationKey
              ? t(`attribute.hint.${hintTranslationKey}`)
              : undefined
          }
        />
      );
    }
    case InputElement.Select:
    case InputElement.MultiSelect:
      return (
        <FormSelect
          isMulti={props.type === InputElement.MultiSelect}
          {...elementProps}
        />
      );

    case InputElement.Range: {
      return <FormRange {...elementProps} />;
    }
    case InputElement.TextArea: {
      return <FormTextArea {...elementProps} />;
    }
    case InputElement.MdEditor: {
      return <FormMdEditor {...elementProps} />;
    }
    case InputElement.CheckboxGroup: {
      return <FormCheckboxGroup {...elementProps} />;
    }
    case InputElement.Checkbox: {
      return <FormCheckbox {...elementProps} />;
    }
    case InputElement.UploadImage: {
      const { name, fileLimit, isReadOnly } = elementProps;

      return (
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <ImageUpload
              onSelectFile={onChange}
              files={value}
              fileLimit={fileLimit}
              isReadOnly={isReadOnly}
            />
          )}
        />
      );
    }
    case InputElement.UploadSpreadsheet: {
      const { name, fileLimit, isReadOnly } = elementProps;

      return (
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <SpreadsheetUpload
              onSelectFile={onChange}
              files={value}
              fileLimit={fileLimit}
              isReadOnly={isReadOnly}
            />
          )}
        />
      );
    }
    case InputElement.Toggle: {
      return <FormToggle {...elementProps} />;
    }
    case InputElement.DatePicker: {
      return <FormDatePicker {...elementProps} />;
    }
    case InputElement.DateRangePicker: {
      return <FormDateRangePicker {...elementProps} />;
    }
    case InputElement.DateTimeRangePicker: {
      return <FormDateTimeRangePicker {...elementProps} />;
    }
    case InputElement.LocationSelect: {
      return <Location {...elementProps} />;
    }
    case InputElement.FrequencyScheduler: {
      return <FormFrequencyScheduler {...elementProps} />;
    }

    default:
      return null;
  }
};
